@import '~@/assets/scss/main.scss';

// Replicate azure AD sign in page styles
#welcome {
    width: 100vw;
    height: 100vh;
    background-color: $primary;
    background-size: cover;
}

.btn {
    width: 18rem;
}

h1 {
    font-size: 36px;
}
