@import '~@/assets/scss/main.scss';

#top-navigation {
    flex-wrap: nowrap;
    min-height: 5rem;
    padding: 0.75rem 2rem;
    background-color: white;
    @include material-shadow($z-depth: 3, $color: $soft-shadow);

    #nav-logo,
    #nav-logo-mobile {
        height: 3rem;
    }

    #page-title {
        font-size: 1.75rem;
        font-weight: 800;
        @extend .font-raleway;
    }

    .course-image-present {
        @include media-breakpoint-down(md) {
            @include visually-hidden;
        }
    }

    #breadcrumb {
        font-size: 1rem;
        @extend .font-raleway;

        .active {
            font-weight: bold;
        }

        @include media-breakpoint-down(sm) {
            max-width: 75vh;
        }
    }

    .breadcrumb-image {
        max-height: 4rem;
    }
}
