@import '~@/assets/scss/main.scss';

.sidebar-wrapper {
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar-wrapper::-webkit-scrollbar {
    display: none;
}

.list-group {
    height: 70vh;
}
